.iros-footer {
  & h2, & h3 {
    font-weight: 100;
    color: var(--white5);
    margin-bottom: .8em;
    text-transform: capitalize;
    line-height: 40px;
  }
  & h2 {
    font-size: 36px;
  }
  & h3 {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
  & .iros-subscribe-btn {
    border-radius: 4px;
    text-transform: uppercase;
    background: linear-gradient(to right,var(--orange),var(--red));
    border:none;
    height: 48px;
    padding: 0 3em;
    font-weight: 700;
  }
  & .iros-form-inline {
    margin-bottom: 2em;
    display: flex;
    flex-direction:row;
    & .iros-form-group {
      border:none;
      height: 48px;
      margin-right: 10px;
      width: 100%;
      @media (max-width: 350px) {
        max-width: 300px;
        width: 100%;
      }
      &::placeholder {
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  & .iros-copyright {
    color: var(--white5);
    font-size: 13px;
    font-weight: 100;
  }
  & .iros-list {
    & li {
      line-height: 2em;
    }
  }
  & .footer-col {
    @media (max-width: 768px) {
      margin-bottom: 0;
      & .iros-copyright {
        display: none;
      }
    }
  }
  & .iros-copyright-mobile {
    @media (min-width: 768px) {
      display:none;
    }
  }

  @media (max-width: 768px) {
    &.footer-above {
      padding-top: 20px;
    }
  }
}
