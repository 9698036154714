/*all general style here*/
body {
  font-family: 'Source Sans Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html, body {
  height: 100%;
}

section {
  & .iros-hw_text {
    font-family: 'source code pro';
    font-weight: bold;
  }
  
  &.success {
    & ul,
    & h2 {
      color: hsla(0,0%,100%,.75);
    }
    & ul {
      line-height: 2em;
      font-size: 18px;
      font-weight: 300;
    }
  }
  &:not(.success) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6,
    & p {
      color: var(--black7);
    }
  }
  & a,
  & a:link,
  & a:hover,
  & a:visited {
    color: var(--linkColor);
    &.iros-btn {
      border-radius: 4px;
      color: #fff;
      height: 70px;
      text-align: center;
      line-height: 70px;
      display: inline-block;
      font-size: 16px;
      margin-top: 3em;
      &:hover {
        text-decoration: none;
      }
      &.iros-btn-purple {
        /*background: linear-gradient(to right,color(var(--purple),a(50%)),var(--blue));*/
        background: linear-gradient(to left,rgb(178,44,244),rgb(171,9,151));
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
        font-size: 15px;

        @media (max-width: 500px) {
          flex-direction:column;
        }
        & span {
          font-size: 1em;
          color: var(--white5);
          line-height: 1.3em;
          text-align: right;
          margin-right: 1em;
          font-weight: 300;
          width: 80px;
          display: block;

          @media (max-width: 500px) {
            width: 100%;
            line-height: 1;
            text-align: center;
            margin:0;
          }
        }
        & b {
          font-size: 2em;

          @media (max-width: 500px) {
            font-size: 1.7em;
            line-height: 1;
          }
        }
        &.iros-btn-purple_started {
          height: 60px;
          font-size: 13px;
          margin-top: 0;
          & span {
            width: 60px;
            @media (max-width: 500px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  & h1 {
    font-size: 35px;
    line-height: 50px;
    font-weight: lighter;
    color: rgba(255,255,255,.9);
    text-transform: none;
  }
  & h2 {
    font-size: 25px;
    font-weight: normal;
    color: rgba(0,0,0,.9);
    text-transform: none;
    margin: 0 0 1em;
    line-height: 35px;
  }
  & h3 {
    margin: 3em 0 1em;
    font-size: 20px;
  }
  & p {
    margin-bottom: 1.5em;
    color: var(--bodyTextColor);
    font-weight: 300;
  }
  & .iron-worker {
    & span {
      position: relative;
      color:white;
      font-weight: bold;
      & svg {
        position: absolute;
        width: 100px;
        bottom: 8px;
        right: -43px;
      }
      &:last-child {
        margin-left: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    padding:20px 0;
  }
}

.product-page {
  & header {
    font-size: 2rem;
    text-align: left;
  }
}

.iros-carousel {
  background: #e22d18;
  background: linear-gradient(150deg,#e22d18,#d9177c);
  background-image: url("../img/gears-bg.jpg");
  background-size: cover;
  line-height: 400px;
  & .item {
    height: 400px;
    line-height: 50px;
    & .carousel-caption {
      bottom: 0;
      font-size: 35px;
      text-shadow: none;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 350px;

      @media (max-width: 767px) {
        font-size: 1.8em;
        line-height: 1.4em;
      }
      & q {
        position: relative;
        &::after,  &::before {
          content: " ";
        }
        & svg {
          fill:#fff;
          width: 50px;
          display: block;
          position: absolute;
          top: -80px;
          left: -40px;

          @media (max-width: 768px) {
            width: 30px;
          }
        }
      }
      & .iros-author {
        & img {
          width: 200px;
          height: auto;
        }
      }
    }
  }
  & .carousel-control {
    & svg {
      width: 56px;
      vertical-align: -webkit-baseline-middle;

      @media (max-width: 768px) {
        width: 30px;
      }
    }
    &.right {
      & svg {
        transform: rotate(180deg);
      }
    }
  }
}

.iros-hr {
  width: 100%;
  max-width: 300px;
  height: 2px;
  border:none;
  &.iros-hr-transparent {
    background: rgba(255,255,255,.5);
  }
}

.iros-idea-section {
  background: #242424 !important;
  padding: 50px 0;

  & h2 {
    font-size: 38px;
    text-transform: none;
    color: white;
    margin-bottom: 24px;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  & .iros-git-btn {
    font-size: 18px;
    color: hsla(0,0%,100%,.5);
    font-weight: 300;
    border: 2px solid hsla(0,0%,100%,.5);
    padding: 10px 20px;
    border-radius: 4px;
    height: 48px;
    display: inline-block;
    margin-top: 24px;

    &:hover {
      background: rgba(0,0,0,.1);
      color: hsla(0,0%,100%,.7);
      border: 2px solid hsla(0,0%,100%,.7);
      text-decoration: none;
    }
  }

  & h3 {
    color: rgba(255,255,255,.75);
    font-weight: normal;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: .5em;
  }

  & p {
    font-size: 20px;
    color: rgba(255,255,255,.5);
    font-weight: lighter;
    line-height: 28px;
    margin-bottom: 0;
    padding: 10px 20px 0;
  }
  & .iros-idea-col {
    border-left: 1px solid rgba(255,255,255,.15);

    @media (max-width: 767px) {
      border-left: none;
      padding-top: 30px;
      &::before {
        content: '';
        height: 2px;
        width: 100px;
        position: absolute;
        top: 0;
        background: rgba(255,255,255,.15);
        left: 0;
        right: 0;
        margin:auto;
      }
      & h3 {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0;
    & .iros-worker {
      margin-top: 4em;
    }
    & .iros-git-btn {
      margin-bottom: 2em;
    }
  }
}

.iros-table-section {
  & .container-computing-block {
    & h1 {
      margin-bottom: 1em;
    }
    & p {
      font-size: 18px;
      color: var(--white5);
      & a {
        color: var(--white5);
        text-decoration: underline;
      }
    }
  }
}

.iros-get-started {
  & h1, & h2, & p {
    color: rgba(0,0,0,.6);
  }
  & h1 {
    margin-top: 0;
    font-size: 3rem;
    margin-bottom: .7em;
  }
  & .iros-img-git {
    width: 40px;
    height: 40px;
    fill: var(--black6);
  }
  & .iros-blue-print {
    width: 100%;
  }
  & .p16 {
    font-size:16px;
  }
  & .border-top {
    border-top: 1px solid rgba(0,0,0,.2);
    padding-top: .4em;
    margin-top: 2em;
  }
  & .iros-logo-block {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    & img {
      margin-right: 1em;
    }
    &.iros-logo-block_mobile {
      display: none;
      justify-content: flex-end;
    }
  }

  @media (max-width: 450px) {
    & .iros-logo-block {
      display: none;
      &.iros-logo-block_mobile {
        display: flex;
      }
    }
  }

  @media (max-width: 767px) {
    & .portfolio-item {
      text-align: center;
    }
  }
}

.iros-portfolio-section {
  background: rgba(0,0,0,.05);
  padding: 0 0 100px;
  & h3 {
    color: var(--black3);
  }
  & .iros-portfolio-item {
    text-align: center;
    & a {
      & img {
        width: 140px;
        height: auto;
        margin: auto;
      }
      & span {
        color: var(--black6);
        text-align: center;
        font-size: 1.4em;
        & small {
          color: var(--black3);
          font-size: .7em;
          font-weight: 100;
        }
      }
      &:hover, &:active {
        color: var(--black6);
      }
    }
  }
}

.iros-content {
  background: rgba(0,0,0,.03);
}
