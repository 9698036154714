/*svg [id="ironio-logo"] {
  fill: white;
}*/

svg [id="ironos"] {
  fill: url('#_Linear1_iron_hand');
}

svg [id="01t_01"],
svg [id="01t_02"],
svg [id="01t_04"],
svg [id="01t_05"],
svg [id="01t_06"],
svg [id="01t_07"],
svg [id="01t_08"] {
  fill: var(--purple);
}

svg [id="01t_03"] {
  fill: transparent;
  stroke: var(--purple);
  stroke-width: 2px;
}

svg [id="02t_01"],
svg [id="02t_02"],
svg [id="02t_03"],
svg [id="02t_04"],
svg [id="02t_05"],
svg [id="02t_06"],
svg [id="02t_07"],
svg [id="02t_08"] {
  fill: var(--blue);
}

svg [id="arrow"] {
  fill: #fff;
}

svg [id="blueprint"] {
  fill: url('#_Radial1_blueprint');
}

svg [id="ft_hover"] {
  stroke: green;
  fill: rgba(0,0,0,0);
}

svg [id="ft_chain"] {
  fill: var(--blueFeature);
}