@charset "utf-8";
@import 'variables.css';

/*Bootstrap Core CSS - Uses Bootswatch Flatly Theme: http://bootswatch.com/flatly/*/
@import 'bootstrap.min.css';
@import 'https://fonts.googleapis.com/css?family=Source+Code+Pro:500,300,400|Source+Sans+Pro:300,400,400i,700';
@import 'freelancer-theme.css';
@import 'base.css';
@import 'tables.css';
@import 'svg.css';
@import 'header.css';
@import 'footer.css';
@import 'responsive.css';
@import '404.css';

/*in general don't add style here*/
