.navbar-nav.iros-navbar > li > a {
  text-transform: none;
  font-weight: normal;
  color: white;
  font-size: 14px;
  line-height: 25px;
  display: inline-flex;

  &:hover,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: underline;
  }
}

.iros-navbar-collapse.collapsing {
  display: none;
}

@media screen and (max-width: 420px) {
  .iros-navbar-collapse.collapse {
    &.in {
      background: rgba(0,0,0,.9);
      margin: 0 0 0 auto;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(255,255,255,.4);
      & .iros-navbar {
        float: right;
        margin-right: 0;
        & li:first-child {
          padding-bottom: 10px;
          & img {
            margin:0 0 0 auto;
          }
        }
        & li {
          & a {
            padding-right: 0;
            text-align: right;
            padding-left: 30px;
          }
        }
      }
    }

  }
}

.iros-navbar {
  & li:first-child {
    & img {
      margin-right: 1em;
    }
  }
  & svg {
    fill:#fff;
    margin-right: .5em;
  }
}

.iros-worker-navbar > li > a {
  color: var(--white8);
}

.navbar-default {
  background: transparent;
  margin-bottom: -60px;
  height: 60px;
  & .navbar-toggle {
    border-color: transparent;
    box-shadow: 0 0 0 1px rgba(255,255,255,.4);
  }
}

.navbar-brand {
  padding: 0 15px;

  & > img,
  & svg {
    width: 120px !important;
    max-height: 60px;
    fill:white;
  }

  @media (max-width: 767px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    width: 150px;
    margin:auto;
    z-index: -1;
  }
}

header {
  text-align: center;
  color: #fff;

  /*var use example*/
  background: #e22d18;
  background: linear-gradient(150deg,#e22d18,#d9177c);
  background-image: url("../img/header-bg.jpg");
  background-size: cover;

  & .container {
    position: relative;
  }

  & .ironio-robot {
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: -40px;
    right: 9vw;
    width: 30vw;
    height: auto;
    max-width: 350px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top {
    padding: 5px 0;
  }

  .navbar-fixed-top.navbar-shrink {
    background: rgba(0,0,0,.4);
  }

  header {
    & .container {
      padding-top: 140px;
      padding-bottom: 80px;
      & img {
        bottom: -3.4vw;
        height: 25vw;
        width: auto;
      }
    }
    & .intro-text .name {
      font-size: 2.5em;
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  header .intro-text .name {
    font-size: 3.5em;
  }
  header {
    & .container {
      padding-top: 140px;
      padding-bottom: 80px;
      min-height: 440px;
      & .row {
        margin-top: 50px;
      }
      & img {
        bottom: -40px;
        width: 30vw;
        height: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    & .container {
      padding-top: 130px;
      padding-bottom: 80px;
      & img {
        bottom: -3.4vw;
        height: 30vw;
        width: auto;
        right: 2vw;
        display: none;
      }
    }
    & .intro-text .name {
      font-size: 2.5em;
      text-align: center;
    }
  }
}
