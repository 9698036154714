.iros-404-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

  & .iros-404-content {
    flex: 1;

    & header {
      height: 100%;

      & .container {
        height: 100%;

        & img {
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          height: 25vw !important;
        }
      }
    }
  }
  & .iros-404-footer {
    flex: 0;
  }
}
