/*table style here*/

.tables-svg {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--white5);
  & > div {
    display: flex;
    flex-direction: column;
    &.bordered-table {
      border-radius: 4px;
      justify-content: flex-start;
      margin-right: 15px;
      font-weight: 100;
      min-width: 270px;
      width: 100%;
      &.purple-table {
        border: 2px solid var(--purple);
        & .table-body {
          color: rgba(255,255,255,.8);
        }
        & .table-header, & .table-footer {
          background: rgba(179,55,243,.23);
        }
        & .table-header {
          border-bottom: 2px solid var(--purple);
        }
        & .table-footer {
          border-top: 2px solid var(--purple);
          & .when-use {
            color: var(--purple);
            font-weight: bold;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &.first-table {
        border: none;
        & .table-body {
          padding: 20px 0;
          font-weight: 100;
          & .table-body_row {
            justify-content: flex-end;
          }
        }
      }
      & .table-header {
        padding: .4em;
        text-align: center;
        min-height: 65px;
        font-weight: 300;
        font-size: 2.3em;
        color:#fff;
      }
      & .table-body {
        padding: 20px;
        font-weight: 400;
        & .table-body_row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items:center;
          min-height: 70px;
          margin: 3em 0;
          & svg {
            height: 50px;
            width: auto;
            margin-bottom: 1.5em;
            &.horizontal {
              height: 50px;
              width: auto;
              max-width: auto;
              &.extra {
                height: 60px;
              }
            }
            &.wos-01t_01,
            &.wos-01t_02,
            &.wos-01t_05,
            &.wos-01t_06 {
              transform: scale(1.3);
            }
            &.wos-01t_03 {
              transform: scale(1.2);
            }
            &.wos-01t_07,
            &.wos-01t_08 {
              transform: scale(1.1);
            }
            &.wos-01t_04 {
              transform: scale(1.6);
            }
          }
          & > div {
            text-align: center;
            font-weight: 300;
            margin: 0 3em;
            font-size: .9em;
            color: hsla(0,0%,100%,.5);
          }
        }
      }
    }
  }
}
